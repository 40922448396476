<!--
 * @Author: vinota
 * @Description: 加上切换tab的Utable表格组件
-->
<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in tabArr" :key="index" :label="item.label" :name="item.key" />
            <Utable :data="tableData" :cm-select="false" select-key="id" table-id="fileTable">
                <el-table-column v-if="$store.state.type==='留学'" label="方案定位">
                    <div slot-scope="scope">
                        <span>{{ scope.row.list[0].schoolMajor }}</span>
                    </div>
                </el-table-column>
                <el-table-column label="类型" width="100">
                    <div slot-scope="scope">
                        <span>{{ scope.row.nodeStr }}</span>
                    </div>
                </el-table-column>
                <el-table-column label="时间" width="150">
                    <div slot-scope="scope">
                        <span>{{ scope.row.time }}</span>
                    </div>
                </el-table-column>
                <el-table-column label="资料名称" width="360">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.list.filter(v => v.checkStatus === 1)" :key="index" style="display:flex;align-items:center;">
                            <span style="display:inline-block;width:280px;">{{ item.name }}</span>
                            <a v-if="item.type < 0" style="margin-left:30px;" :class="item.contentUrl && item.contentUrl.length > 0 && item.contentUrl[0].url !== '' && item.contentUrl[0].url !== null ? 'blue-c' : 'grey-c'" @click="downLoad(item)">下载</a>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="适用申请人" width="150">
                    <template slot-scope="scope">
                        <div v-for="item in scope.row.list.filter(v => v.checkStatus === 1)" :key="item.id">{{ item.applicantName }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="快递">
                    <div slot-scope="scope">
                        <div v-if="scope.row.expressNo===''||scope.row.expressNo===null" class="blue-color" @click="addPost(scope.row)">添加</div>
                        <div v-else>
                            {{ scope.row.expressCompany }} {{ scope.row.expressNo }}
                        </div>
                    </div>
                </el-table-column>
            </Utable>
        </el-tabs>
        <!-- 收获地址弹出层 -->
        <CustomDialog :visible.sync="dialogVisible" width="650px" title="填写邮寄单号信息" class="address-overlay" @confirm="handleSubmitForm('param')" @cancel="dialogVisible = false">
            <el-form ref="param" :model="param" :rules="rules" size="medium" label-width="120px">
                <el-form-item label="寄件人:" prop="consignor">
                    <el-input v-model="param.consignor" placeholder="请输入" clearable maxlength="20" show-word-limit />
                </el-form-item>
                <el-form-item label="快递公司:" prop="expressCompany">
                    <el-input v-model="param.expressCompany" placeholder="请输入" clearable maxlength="30" show-word-limit />
                </el-form-item>
                <el-form-item label="快递单号:" prop="expressNumber">
                    <el-input v-model="param.expressNumber" placeholder="请输入" clearable maxlength="30" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" show-word-limit />
                </el-form-item>
                <el-form-item label="补充备注:">
                    <el-input v-model="param.remark" type="textarea" placeholder="请输入" maxlength="200" show-word-limit />
                </el-form-item>
            </el-form>
        </CustomDialog>
    </div>
</template>

<script lang="javascript">
import CustomDialog from "@/components/CustomDialog/CustomDialog.vue";
import Utable from "@/components/Utable/Utable.vue";
import { MailInfo, saveMailInfo } from "@/api/workfow.js";
export default {
    name: "PostTable",
    components: {
        Utable,
        CustomDialog,
    },
    data() {
        return {
            tabArr: [
                {
                    label: "初审阶段",
                    key: "1",
                },
                {
                    label: "面试阶段",
                    key: "3",
                },
                {
                    label: "续签阶段",
                    key: "5",
                },
                {
                    label: "永居阶段",
                    key: "7",
                },
            ],
            tableData: [],
            activeName: "1",
            dialogVisible: false,
            param: {
                consignor: "",
                expressCompany: "",
                expressNumber: "",
                remark: "",
            },
            rules: {
                consignor: [
                    {
                        type: "string",
                        required: true,
                        message: "请输入寄件人",
                        trigger: "change",
                    },
                ],
                expressCompany: [
                    {
                        type: "string",
                        required: true,
                        message: "请输入快递公司",
                        trigger: "change",
                    },
                ],
                expressNumber: [
                    {
                        type: "string",
                        required: true,
                        message: "请输入快递单号",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created() {
        //渲染table数据
        //key 1初审资料  2补件 3面试 4面试补件 5续签 6续签补件 7永居 8永居补件 9 粉签 10 粉签补件
        this.getTableData();
        if (this.$store.state.type === "留学") {
            this.tabArr = [
                {
                    label: "申请阶段",
                    key: "1",
                },
                {
                    label: "学生签证阶段",
                    key: "9",
                },
                {
                    label: "续期阶段（IANG签证）",
                    key: "5",
                },
            ];
        } else {
            this.tabArr = [
                {
                    label: "初审阶段",
                    key: "1",
                },
                {
                    label: "面试阶段",
                    key: "3",
                },
                {
                    label: "续签阶段",
                    key: "5",
                },
                {
                    label: "永居阶段",
                    key: "7",
                },
            ];
        }
    },
    mounted() {},
    methods: {
        // 下载
        downLoad(row) {
            let timestamp = new Date().getTime();
            let data = row.contentUrl;
            if (data && data.length > 0 && data[0].url !== "") {
                window.open(this.$utils.toOss(data[0].url + "?timestamp=" + timestamp));
            } else {
                this.$message.error("文案经理还未上传模板");
            }
        },
        getTableData(id) {
            console.log(id)
            let params = {
                orderId:this.$store.state.user.orderId,
                node: this.activeName,
            };
            if (this.$store.state.type === "留学") {
                params.planId = id||"";
            }
            MailInfo(params).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data;
                }
            });
        },
        //切换Tab标签
        handleClick() {
            this.getTableData();
        },
        //添加快递
        addPost(row) {
            if (this.$refs.param) {
                this.$refs.param.resetFields();
            }
            this.param.remark = "";
            this.param.orderId = row.list[0].orderId;
            this.param.node = row.list[0].node;
            this.param.consignor = JSON.parse(
                localStorage.getItem("user")
            ).userName;
            this.dialogVisible = true;
        },
        // 提交快递信息
        handleSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let {
                        consignor,
                        expressCompany,
                        expressNumber,
                        orderId,
                        node,
                        remark,
                    } = this.$refs[formName].model;
                    let Data = {
                        consignor,
                        expressCompany,
                        expressNumber,
                        orderId,
                        node,
                        remark,
                    };
                    saveMailInfo(Data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.data);
                            this.dialogVisible = false;
                            this.getTableData();
                        }
                    });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-table-wrapper {
    border: none;
}

.blue-color {
    color: #409eff;
    cursor: pointer;
}

.blue-color {
    font-size: 14px;

    font-weight: 400;
    color: #409eff;
}
.blue-c {
    color: #409eff;
}
.grey-c {
    color: #a9a9a9;
}
/deep/.el-tabs__active-bar {
    margin-left: 14px;
}

/deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    margin-left: 14px;
}
</style>
