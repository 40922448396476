<!--
 * @Author: Jason
 * @Description: 自定义弹出层组件
-->
<template>
    <el-dialog 
        :visible.sync="visibleDialog" 
        v-bind="$attrs" 
        v-on="$listeners"
    >
        <slot />
        <!--使用弹框的footer插槽添加按钮-->
        <template #footer>
            <slot name="footer">
                <span>
                    <el-button @click="handleCancel">取 消</el-button>
                    <el-button type="primary" @click="handleConfirm">
                        确 定
                    </el-button>
                </span>
            </slot>
        </template>
    </el-dialog>
</template>
<script>
export default {
    name: "CustomDialog",
    inheritAttrs: false,
    computed: {
        visibleDialog: {
            get() {
                return this.$attrs.visible;
            },
            set(val) {
                this.$emit("update:visible", val);
            }
        }
    },
    methods: {
        // 对外抛出cancel事件
        handleCancel() {
            this.$emit("cancel");
        },
        handleConfirm() {
            this.$emit("confirm");
        }
    }
};
</script>
