<template>
    <el-table v-bind="finalAttrs" :data="data" height="500" border stripe class="el-table-wrapper" :row-class-name="tableRowClassName" :header-cell-style="headerCellStyle" v-on="$listeners">
        <el-table-column v-if="cmSelect" :render-header="renderHeader" width="50">
            <template slot-scope="scope">
                <div>
                    <input :id="tableId + scope.$index" v-model="checkedArr" type="checkbox" hidden :value="getKey(scope.row,selectKey)">
                    <label :for="tableId + scope.$index" class="checkbox-inner" :class="{'checked': checkedArr.indexOf(scope.row[selectKey]) > -1}" />
                </div>
            </template>
        </el-table-column>
        <slot />
    </el-table>
</template>

<script>
import utils from "@/assets/js/common/utils";
export default {
    name: "Utable",
    inheritAttrs: false,
    props: {
        // 表格的数据（对应el-table的data）
        data: {
            type: Array,
            default: () => [],
        },
        // 行多选(一列选择框)
        cmSelect: {
            type: Boolean,
            default: false,
        },
        // tableId主要用于input框及label，如果不传，出现多个有行多选功能的表格时，id会冲突
        tableId: {
            type: String,
            default: "ak-table",
        },
        // id的访问路径。例如："aa.xx.id" 对应 vm.data[i][aa][xx][id]
        selectKey: {
            type: String,
            default: "id",
        },
        checkedIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            finalAttrs: {}, // 用于保存设置默认值之后的$attrs
            // el-table的默认配置项
            defaultConfig: {
                border: true,
                stripe: true,
            },
            isAllSelect: false,
            isPartSelect: false, // 部分选择
            currIdArr: [],
            flag: true,
        };
    },
    computed: {
        checkedArr: {
            get: function () {
                return JSON.parse(JSON.stringify(this.checkedIds));
            },
            set: function (value) {
                this.$emit(
                    "update:checkedIds",
                    JSON.parse(JSON.stringify(value))
                );
                this.flag = value.length !== 0; // 将flag状态恢复,为了第二次删除的时候能够正常使用

                // 全选状态
                if (this.data) {
                    this.isAllSelect =
                        utils.isContained(value, this.currIdArr) &&
                        value.length > 0;
                } else {
                    this.isAllSelect = false;
                }
                // 部分选择
                if (value.length > 0) {
                    this.isPartSelect = !this.isAllSelect;
                } else {
                    this.isAllSelect = false;
                    this.isPartSelect = false;
                }
            },
        },
    },
    watch: {
        $attrs: function (value) {
            let vm = this;
            // $attrs虽然可以获取父组件传入的、子组件未声明的props，但是还需要配一些默认值
            vm.finalAttrs = Object.assign({}, vm.defaultConfig, value);
        },
        // 监听外面传进来数组的变化,如果数组为清空,并且当前为可以第一次更新时,触发checkedArr的set函数
        checkedIds: function () {
            if (this.checkedIds.length === 0 && this.flag) {
                this.flag = false; // 调用checkedArr的更新之后,需要将状态更改,防止checkedArr的更新影响到这个函数再次调用时发生死循环
            }
        },
    },
    mounted: function () {
        let vm = this;
        vm.$nextTick(() => {
            // 判断全选状态
            vm.currIdArr = [];
            vm.data.forEach(function (item, index) {
                vm.currIdArr.push(vm.getKey(item, vm.selectKey));
            });
            if (vm.$attrs) {
                vm.finalAttrs = JSON.parse(JSON.stringify(vm.$attrs));
            } else {
                vm.finalAttrs = {};
            }
            // $attrs虽然可以获取父组件传入的、子组件未声明的props，但是还需要配一些默认值
            for (let i in vm.defaultConfig) {
                if (!Object.prototype.hasOwnProperty.call(vm.finalAttrs, i)) {
                    vm.finalAttrs[i] = vm.defaultConfig[i];
                }
            }
        });
    },
    methods: {
        refresh: function () {
            this.reload();
        },
        /*
        为取到更深层级的key
        * vm.data[i][aa][xx][id]
        * */
        getKey: function (item, keyString) {
            let keyArr = keyString.split(".");
            let temArr = [item[keyArr[0]]];
            for (let i = 1; i < keyArr.length; ++i) {
                temArr.push(temArr[i - 1][keyArr[i]]);
            }
            return temArr[temArr.length - 1];
        },
        renderHeader(createElement, { column, _self }) {
            let vm = this;
            return createElement(
                "div",
                {
                    class: "header-center header-frist-child",
                },
                [
                    createElement(
                        "input",
                        {
                            class: "header-center",
                            style: {
                                display: "none",
                            },
                            attrs: {
                                type: "checkbox",
                                id: vm.tableId + "-select-all",
                            },
                            domProps: {
                                checked: vm.isAllSelect,
                            },
                            on: {
                                change: function (event) {
                                    vm.isAllSelect = event.target.checked;
                                    if (event.target.checked) {
                                        vm.currIdArr.forEach(function (value) {
                                            if (
                                                vm.checkedArr.indexOf(value) ===
                                                -1
                                            ) {
                                                vm.checkedArr.push(value);
                                            }
                                        });
                                    } else {
                                        vm.currIdArr.forEach(function (value) {
                                            if (
                                                vm.checkedArr.indexOf(value) !==
                                                -1
                                            ) {
                                                vm.checkedArr.splice(
                                                    vm.checkedArr.indexOf(
                                                        value
                                                    ),
                                                    1
                                                );
                                            }
                                        });
                                    }
                                    // 直接给vm.checkedArr赋值为一个对象才能被computed检测到。（$set和$delete不行）
                                    vm.checkedArr = JSON.parse(
                                        JSON.stringify(vm.checkedArr)
                                    );
                                },
                            },
                        },
                        []
                    ),
                    createElement(
                        "label",
                        {
                            class: {
                                "header-center": true,
                                "checkbox-inner": true,
                                checked: vm.isAllSelect,
                                partChecked: vm.isPartSelect,
                            },
                            attrs: {
                                for: vm.tableId + "-select-all",
                            },
                        },
                        [""]
                    ),
                ]
            );
        },
        tableRowClassName({ row, rowIndex }) {
            /* 选中当前颜色 */
            if (this.checkedArr.indexOf(row[this.selectKey]) > -1) {
                return "row-checked";
            }
            return "";
        },
        headerCellStyle({ row, column, rowIndex, columnIndex }) {
            // 第一行，如果有选择框则从第一列开始，否则从第0列开始
            if (
                rowIndex === 0 &&
                ((this.cmSelect && columnIndex > 0) || !this.cmSelect)
            ) {
                let fontSize = 14; // 表头字体大小
                let length = column.label.length;
                column.minWidth = 55 + length * fontSize;
                return column;
            }
        },
    },
};
</script>

<style scoped lang="less">
.el-table-wrapper {
    // border-radius: 4px;
    // border-width: 1px;
    // border-style: solid;
    /*表头*/
    /deep/ .el-table__header-wrapper {
        letter-spacing: 2px;
        /*去除表头第一个label的左边距*/
        .cell {
            font-weight: 700;
        }
        th {
            // padding: 9px 0;
        }
        /*表格的多选框*/
        th div {
            padding: 0;
            height: 22px;
            line-height: 22px;
        }
    }
    /*主体*/
    /deep/ .el-table__body-wrapper {
        .el-table__row td {
            // padding: 9px 0;
            // border: 0;
        }
    }
    /deep/ .cell {
        line-height: 22px;
        // padding-left: 16px !important;
    }
}
/*label输入框居中*/
/deep/ .checkbox-inner {
    vertical-align: middle;
}

.el-table__empty-block {
    min-height: 50px;
}
</style>

