<!--
 * @Author: vinota
 * @Description: 邮寄原件页面
-->
<template>
    <div>
        <div class="blue-cont">
            <div class="top-title">
                <div class="name">邮寄原件</div>
                <div class="current-progress">根据入境处要求您的部分资料须递交原件，包括原始资料（特别指盖章的文件)，邮寄后请及时添加邮寄单号</div>
            </div>
        </div>
        <div class="text-box">
            <!-- 仅留学展示 -->
            <div v-if="$store.state.type==='留学'" class="input-cont">
                <el-select v-model="schemePosition" placeholder="请选择方案定位" clearable @change="schemeChange">
                    <el-option v-for="item in options" :key="item.id" :label="item.schoolMajor" :value="item.id" />
                </el-select>
            </div>
            <div class="post-title">邮寄清单</div>
            <div class="post-box">
                <PostTable ref="PostTable" />
            </div>
            <div class="post-title marg-top">邮寄清单地址</div>
            <div class="post-box marg-bottom">
                <div class="one-line">
                    <span class="title-tag">收件地址:</span>
                    <span class="tag-cont">广东省深圳市福田区深南大道6015号本元大厦26A（银河集团），邮编：518040。</span>
                </div>
                <div class="one-line">
                    <span class="title-tag">收件人:</span>
                    <span class="tag-cont post-one"> - 请联系文案</span>
                </div>
                <div class="one-line t-bottom">
                    <span class="title-tag">联系电话:</span>
                    <span class="tag-cont"> - 请联系文案 </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="javascript">
import { mailAddress, orderPlan } from "@/api/workfow.js";
import PostTable from "./C/PostTable";
export default {
    name: "PostFile",
    components: {
        PostTable,
    },
    data() {
        return {
            activeName: "1",
            mailPerson: {
                address: "",
                name: "",
                mobile: "",
            },
            schemePosition: "", // 方案定位
            options: [],
        };
    },
    created() {
        this.$store.commit("user");
        this.$store.commit("setType");
        let params = { orderId: this.$store.state.user.orderId };
        this.orderPlanFun();
        mailAddress(params).then((res) => {
            if (res.code == 200) {
                if (res.data == null) {
                    this.mailPerson = {
                        address: "",
                        name: "",
                        mobile: "",
                    };
                } else {
                    this.mailPerson = res.data;
                }
            }
        });
    },
    methods: {
        // 留学方案定位下拉框
        orderPlanFun() {
            orderPlan({
                orderId: this.$store.state.user.orderId,
            }).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.options = res.data.orderPlanList;
                }
            });
        },
        // 方案切换
        schemeChange(e) {
            this.$refs.PostTable.getTableData(this.schemePosition);
        },
    },
};
</script>

<style lang="less" scoped>
.blue-cont {
    width: 100%;
    height: 130px;
    background: linear-gradient(313deg, #74cdff 0%, #409eff 100%);
}

.top-title {
    width: 960px;
    height: 130px;
    margin: 0 auto;
}

.name {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
    padding-top: 28px;
    margin-bottom: 12px;
}

.current-progress {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 6px;
}

.sign-time {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}

.text-box {
    width: 960px;
    margin: 0 auto;
    padding-top: 40px;
}
.post-title {
    height: 36px;
    background: #409eff;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
    padding-left: 16px;
}
.post-box {
    border: 1px solid #dcdfe6;
    border-top: none;
}
.marg-bottom {
    margin-bottom: 236px;
}
.marg-top {
    margin-top: 24px;
}
.one-line {
    padding-left: 16px;
    padding-top: 10px;
}
.title-tag {
    font-size: 14px;
    font-weight: 400;
    color: #303133;
}
.tag-cont {
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    margin-left: 8px;
}
.post-one {
    margin-left: 22px;
}
.t-bottom {
    padding-bottom: 16px;
}
.input-cont {
    margin-bottom: 20px;
}
</style>
