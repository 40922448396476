const utils = {
    // 判断数组a是否包含数组b的元素,是返回true,不是返回false
    isContained: function (a, b) {
        if (!(a instanceof Array) || !(b instanceof Array)) {
            return false;
        }
        if (a.length < b.length) return false;
        for (let i = 0; i < b.length; i++) {
            if (a.indexOf(b[i]) === -1) {
                return false;
            }
        }
        return true;
    }  


};

export default utils;
